import { useState, useEffect } from "react";

const NoInternetConnection = (props) => {
    const [isOnline, setOnline] = useState(true);

    useEffect(() => {
        setOnline(navigator.onLine);
    }, []);

    window.addEventListener("online", () => {
        setOnline(true);
    });

    window.addEventListener("offline", () => {
        setOnline(false);
    });

    if (isOnline) {
        return props.children;
    } else {
        return (
            <main className="main">
                <div className="container">
                    <p>Нет интернет-соединения</p>
                </div>
            </main>
        );
    }
};

export default NoInternetConnection;

import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import notFound from "../../assets/images/stubs/not-found.webp";
import s from "./style.module.sass";

const NotFound = () => {
    return (
        <div className="container">
            <div className={s.wrapper}>
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>

                <h1>Страница не найдена</h1>
                <img src={notFound} alt="not found page" width={836} height={362} />
                <p className={s.text}>
                    Увы, мы не смогли найти страницу, которую вы ищете. Возможно, вы ввели неправильный адрес или
                    страница была удалена.
                </p>
                <NavLink className={s.btn} to="/">
                    Вернуться на главную
                </NavLink>
            </div>
        </div>
    );
};

export default NotFound;

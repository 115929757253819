import "normalize.css";
import { Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import NoInternetConnection from "./NoInternetConnection";
import Loader from "./components/loader/Loader";
import UseLazy from "./store/hooks/useLazy";
import NotFound from "./components/404/NotFound";
import { Helmet } from "react-helmet";

const MainPage = UseLazy("components/index/MainPage");

const App = () => {
    return (
        <div
            style={{
                flexGrow: 1,
                display: "flex",
                flexFlow: "column nowrap",
                minHeight: "100vh",
            }}>
            <Helmet>
                <title>«Прораб» — поиск исполнителей и заказчиков в строительной сфере</title>
            </Helmet>

            <NoInternetConnection>
                <main className="main">
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Suspense fallback={<Loader />}>
                                    <MainPage />
                                </Suspense>
                            }></Route>

                        <Route path="/404" element={<NotFound />}></Route>
                    </Routes>
                </main>
            </NoInternetConnection>
        </div>
    );
};

export default App;
